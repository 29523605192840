<template>
  <div>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <div style="padding: 1rem" class="flex-def flex-zBetween flex-cCenter">
        <div>
          <y_choose_merchant v-model="search_merchant_id"></y_choose_merchant>
          <el-button @click="search">搜索</el-button>
          <el-button @click="restSearch">重置</el-button>
        </div>
        <div style="text-align: right">
          <el-button @click="dialogTableVisible=true" size="medium" type="primary" icon="el-icon-edit">新增</el-button>
        </div>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" ></el-table-column>
        <el-table-column prop="title" label="标题" ></el-table-column>
        <el-table-column label="缩略图">
          <template #default="s">
            <img v-if="s.row.poster" style="width: 3rem;height: 3rem;border-radius: .5rem" :src="s.row.poster | tomedia" alt="">
          </template>
        </el-table-column>
        <el-table-column label="视频">
          <template #default="s">
            <a :href="s.row.src | tomedia" target="_blank">点击预览</a>
          </template>
        </el-table-column>
        <el-table-column prop="merchant.title" label="所属商家"></el-table-column>
        <el-table-column prop="status" label="状态" :formatter="(item)=>item.status ? '显示' : '隐藏'"></el-table-column>
        <el-table-column prop="goods_id" :formatter="(e)=>e.goods_id ? e.goods_id : ''" label="关联商品编号" ></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="del(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑视频" :visible.sync="dialogTableVisible" destroy-on-close @close="resetSubmit">
      <div class="flex-def flex-zCenter">
        <el-form :model="form" label-width="5rem" style="width: 40rem">
          <el-form-item label="所属商家">
            <y_choose_merchant v-model="form.merchant_id"></y_choose_merchant>
          </el-form-item>
          <el-form-item label="缩略图">
            <y_upload_img v-model="form.poster"></y_upload_img>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="视频">
            <y_upload_video v-model="form.src"></y_upload_video>
          </el-form-item>
          <el-form-item label="显示">
            <el-switch v-model="form.status"></el-switch>
          </el-form-item>
          <el-form-item label="关联商品">
            <y_merchant_goods_choose v-model="form.goods_id" :merchant_id="form.merchant_id"></y_merchant_goods_choose>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
import y_upload_video from "@/components/y_upload_video";
import y_choose_merchant from "@/components/y_choose_merchant";
import y_merchant_goods_choose from "@/components/plugin/shop/y_merchant_goods_choose";
export default {
  name: "ivideo",
  components:{
    y_upload_img,
    y_upload_video,
    y_choose_merchant,
    y_merchant_goods_choose,
  },
  data(){
    return{
      dialogTableVisible:false,
      list:[],
      page:1,
      total:0,
      form:{
        merchant_id:0,
        poster:"",
        src:"",
        status:false,
        title:"",
      },
      loading:false,
      searchFrom:{
        merchant_id:0
      },
      search_merchant_id:0,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    del(id){
      this.$api.plugin.ivideo.del({
        id,
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    submit(){
      this.$api.plugin.ivideo.edit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.resetSubmit();
        this.load();
      })
    },
    restSearch(){
      this.search_merchant_id = 0;
      this.search();
    },
    search(){
      this.searchFrom.merchant_id = this.search_merchant_id;
      this.page = 1;
      this.list.length = 0;
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.plugin.ivideo.search({
        page:this.page,
        merchant_id:this.searchFrom.merchant_id
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.loading = false;
      })
    },
    resetSubmit() {
      this.form = {
        poster:"",
        src:"",
        status:false,
        merchant_id:0,
      };
    },
    edit(e){
      this.form = {
        ...e
      };
      this.dialogTableVisible = true;
    }
  }
}
</script>

<style scoped>

</style>